import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class JobTagService {
  getItems() {
    return axios.get(API_URL + `admin/job_tags`, { headers: authHeader() });
  }

  get(id) {
    return axios.get(API_URL + `admin/job_tags/${id}`, { headers: authHeader() });
  }

  save(bu) {
    return axios.post(API_URL + `admin/job_tags/${bu.id}`, bu, { headers: authHeader() });
  }

  async create(c) {
    const { data } = await axios.post(API_URL + `admin/job_tags/`, c, { headers: authHeader() });
    return data;
  }

  delete(id) {
    return axios.delete(API_URL + `admin/job_tags/${id}`, { headers: authHeader() });
  }
}

export default new JobTagService();
